import React from "react"
import { addBetweenArayElements, capitalizeString } from "../utils/common"
import useScreenSize from "../utils/useScreenSize"

const primaryColor = "#106570"
const primaryColorDark = "#003c43"
const textColor = "#fae17e"
const textColorDark = "#dfaf05"

const StyledParagraph = ({
  id,
  version,
  orientation,
  textOrientation,

  children
}) => {
  const [isSmallScreen] = useScreenSize()

  const style = { textAlign: textOrientation }

  let className = `paragraph`

  const background = []

  switch (version) {
    case "secondary":
      background.push(textColorDark)
      background.push(textColorDark)
      style.color = primaryColor
      break
    case "primary":
    default:
      background.push(primaryColorDark)
      background.push(primaryColorDark)
      style.color = textColor
      break
  }

  background.push("transparent")

  let direction = undefined
  switch (textOrientation) {
    case "right":
      direction = "left"
      break
    case "left":
    default:
      direction = "right"
      break
  }

  switch (orientation) {
    case "horizontal":
      background.unshift(`to ${direction}`)

      style[`padding${capitalizeString(direction)}`] = isSmallScreen
        ? "10%"
        : "30%"
      style[`padding${capitalizeString(textOrientation)}`] = "5%"

      break
    case "vertical":
    default:
      //do nothing
      break
  }

  style.background = `linear-gradient(${String(background)})`

  return (
    <div id={id} style={style} className={className}>
      {children}
    </div>
  )
}

const Paragraph = ({ title, text, textDivided, children, ...props }) => {
  let texts = text ? (Array.isArray(text) ? text : [text]) : undefined
  texts = addBetweenArayElements(texts, "")

  return (
    <StyledParagraph {...props}>
      {title && (
        <div className={texts ? "paragraph-title" : "paragraph-title-big"}>
          {title}
        </div>
      )}
      {texts &&
        texts.map((txt, i) => (
          <div className={textDivided || i === 0 ? "paragraph-part" : ""}>
            {txt}
          </div>
        ))}
      {children}
    </StyledParagraph>
  )
}

export const Paragraphs = ({ paragraphs, className }) => (
  <div class={`paragraphs-horizontal ${className}`}>
    {paragraphs &&
      paragraphs.map((paragraph, i) => <Paragraph {...paragraph} index={i} />)}
  </div>
)

export default Paragraph
