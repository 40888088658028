import { Button, Layout } from "antd"
import React from "react"

const { Footer } = Layout

export default function PageFooter({ labels }) {
  const onContactClick = () => {
    window.location = "mailto:info@lunartech.hr"
  }

  return (
    <div className="background d-flex justify-content-center">
      <Button
        type="primary"
        className="primary-button"
        onClick={onContactClick}
      >
        {labels.contactUs}
      </Button>

      <Footer className="page-footer d-flex align-items-center ">
        <div className="footer-content d-flex align-items-center ">
          <div className="">
            <div>{labels.lunartechFooter1}</div>
            <div>{labels.lunartechFooter2}</div>
            <div className="normal-links">
              <a
                href="http://www.lunartech.hr"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.lunartech.hr
              </a>{" "}
              {", "}
              <a
                href="http://www.sace.hr"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.sace.hr
              </a>
              {", "}
              <a
                href="http://www.dsace.eu"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.dsace.eu
              </a>
            </div>
            <div className="normal-links">
              {labels.lunartechFooter3}
              <a href="tel:+385989843340">+385 98 9843340</a>
              {labels.lunartechFooter4}
              <a href="mailto:info@lunartech.hr">info@lunartech.hr</a>
            </div>
          </div>
        </div>
      </Footer>
    </div>
  )
}
