import { MenuOutlined } from "@ant-design/icons"
import { Col, Image, Layout, Menu, Row } from "antd"
import React from "react"
import useScreenSize from "../utils/useScreenSize"

const { Header } = Layout
const { SubMenu } = Menu

const constructMenu = (menuItems, isMiddleScreen, labels, parentI) => {
  return (
    menuItems &&
    menuItems.map((item, i) => {
      if (item.subItems) {
        return (
          <SubMenu key={i} title={item.title} popupClassName="header-submenu">
            {constructMenu(item.subItems, isMiddleScreen, labels, i)}
          </SubMenu>
        )
      } else {
        const itemKey = `${parentI}-i`

        let content = undefined
        switch (item.type) {
          case "divider":
            content = isMiddleScreen ? (
              <Menu.Divider className="menu-item-divider" />
            ) : (
              <Menu.Item key={itemKey}>
                <div>
                  <div className="menu-item-divider left-bordered-menu-item">
                    .
                  </div>
                </div>
              </Menu.Item>
            )
            break
          case "external-link":
            content = (
              <a href={item.href} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            )
            break
          default:
            content = <a href={item.href}>{item.title}</a>
            break
        }

        return item.type === "divider" ? (
          content
        ) : (
          <Menu.Item key={itemKey}>{content}</Menu.Item>
        )
      }
    })
  )
}

const PageHeaderMenu = ({ labels, isMiddleScreen, menuItems }) => (
  <Menu
    mode="horizontal"
    className="header-menu"
    overflowedIndicator={<MenuOutlined className="header-icon" />}
  >
    {constructMenu(menuItems, isMiddleScreen, labels)}
  </Menu>
)

export default function PageHeader({ labels }) {
  const [, isMiddleScreen] = useScreenSize()
  const onHomeClick = () => {
    document.location.href = labels.homePath
  }

  const menuItems = [
    {
      title: labels.products,
      subItems: [
        {
          title: labels.saceDigitalServices,
          href: `${labels.homePath}#${labels.saceParagraphId}`
        },
        {
          title: labels.eArchiveDigitalLocker,
          href: `${labels.homePath}#${labels.eArchiveParagraphId}`
        },
        {
          title: labels.eTravelBookDigitalAssistant,
          href: `${labels.homePath}#${labels.eTravelBookParagraphId}`
        }
      ]
    },
    {
      title: labels.services,
      subItems: [
        {
          title: labels.centralizedDigitalCommunication,
          href: `${labels.homePath}#${labels.cdcParagraphId}`
        },
        {
          title: labels.businessProcessAutomatization,
          href: `${labels.homePath}#${labels.bpaParagraphId}`
        },
        {
          title: labels.remoteWork,
          href: `${labels.homePath}#${labels.remoteWorkParagraphId}`
        },
        {
          title: labels.ictSystemSolutionDesign,
          href: `${labels.homePath}#${labels.ictParagraphId}`
        }
      ]
    },
    { title: labels.contact, href: labels.contactPath },
    { title: labels.about, href: labels.aboutPath },
    {
      title: labels.blog,
      subItems: [
        {
          title: labels.digitalLockerBlog,
          href: "https://youtu.be/iHGmNJKstbo",
          type: "external-link"
        },
        {
          title: labels.complaintManagementBlog,
          href: "https://youtu.be/2lls4xb2BB0",
          type: "external-link"
        },
        {
          title: labels.eTravelBookBlog,
          href: "https://youtu.be/dFenZbbSJl0",
          type: "external-link"
        }
      ]
    },
    { type: "divider" },
    {
      title: labels.sace,
      subItems: [
        {
          title: "www.sace.hr",
          href: "http://www.sace.hr",
          type: "external-link"
        },
        {
          title: "www.dsace.eu",
          href: "http://www.dsace.eu",
          type: "external-link"
        }
      ]
    },
    {
      title: labels.demo,
      subItems: [
        {
          title: labels.loginAsMerchant,
          href: labels.loginAsMerchantAddress,
          type: "external-link"
        },
        {
          title: labels.loginAsBank,
          href: labels.loginAsBankAddress,
          type: "external-link"
        },
        {
          title: labels.loginAsAccountant,
          href: labels.loginAsAccountantAddress,
          type: "external-link"
        }
      ]
    },
    {
      title: (
        <div className="d-flex justify-content-center align-items-center">
          <Image
            className="flag-img"
            src={labels.nextLanguageFlagImg}
            preview={false}
            alt={labels.nextLanguageFlagImgAlt}
          />
        </div>
      ),
      href: labels.nextHomePath
    }
  ]

  return (
    <Header className="page-header">
      <div className="logo d-flex">
        <Row
          className="d-flex align-self-center clickable"
          onClick={onHomeClick}
        >
          <Col className="d-flex align-self-center">
            <img
              src="/logo.png"
              className="logo-img align-self-center"
              alt="sace-logo"
            />
          </Col>

          <Col className="d-flex align-self-center header-title">
            <div className="align-self-center">{labels.lunartech}</div>
          </Col>

          {/* <Col className="d-flex align-self-center">
            <HomeOutlined className="header-icon" />
          </Col> */}
        </Row>
      </div>

      <PageHeaderMenu
        labels={labels}
        isMiddleScreen={isMiddleScreen}
        menuItems={menuItems}
      />
    </Header>
  )
}
