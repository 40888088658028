import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"

const useScreenSize = () => {
  const breakpoints = useBreakpoint()

  const isSmallScreen =
    (breakpoints.xs || breakpoints.sm || breakpoints.md) &&
    !(breakpoints.lg || breakpoints.xl || breakpoints.xxl)

  const isMiddleScreen =
    (breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.lg) &&
    !(breakpoints.xl || breakpoints.xxl)

  return [isSmallScreen, isMiddleScreen]
}

export default useScreenSize
