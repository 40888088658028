import { Layout } from "antd"
import React from "react"
import { Helmet } from "react-helmet"
import PageFooter from "./page-footer"
import PageHeader from "./page-header"

const { Content } = Layout

export default function Page({ title, description, children, ...props }) {
  title = title ? `${title} | ` : undefined
  const helmetTitle = `${title ? title : ""}LunarTech.hr`

  return (
    <Layout className="layout">
      <Helmet title={helmetTitle} />
      <PageHeader {...props} />
      <Content>{children}</Content>
      <PageFooter {...props} />
    </Layout>
  )
}
