export const capitalizeString = string => {
  if (typeof string !== "string") return ``

  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const addBetweenArayElements = (array, addition) => {
  return array && Array.isArray(array)
    ? array.reduce((r, a, i) => {
        r.push(a)
        if (i < array.length - 1) {
          r.push(addition)
        }
        return r
      }, [])
    : undefined
}
